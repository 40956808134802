module.exports = {
  defaultLang: 'en',
  testnet: {
    contractAddress: 'cfxtest:acduca8bj4hbpe6df71046c4ns260kcd6yewbnfrdx',
    core: {
      name: "Conflux Testnet", 
      RPC: 'https://cfx-node.stake.dxpool.in',
      networkId: 1
    },
    explorer: 'https://testnet.confluxscan.io/transaction/'
  },
  mainnet: {
    contractAddress: '',
    core: {
      name: "Conflux Hydra", 
      RPC: 'https://main.confluxrpc.com',
      networkId: 1029
    },
    explorer: ''
  },
  baseUrl: 'https://cfx.stake.dxpool.in/api/v1'
}